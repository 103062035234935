<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('exam_schedule1')"
                        :isFilter="false"
                        :isBackButton="isBackBtn"
                        :backButton="backBtn">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('exam_schedule1')"
                              :isFilter="false"
                              :isBackButton="isBackBtn"
                              :backButton="backBtn">
                </HeaderMobile>
            </template>
            <div class="d-flex justify-content-end mb-2">
                <json-excel :data="examExcel"
                            :fields="examFieldsJson"
                            type="xls"
                            >
                    <b-button variant="primary" class="mr-2">
                        <i class="ri-file-excel-2-line mr-2"></i> {{ $t('excel') }}
                    </b-button>
                </json-excel>
            </div>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>

            <CommonModal ref="classroom-modal" :onHideOnlyX="true" @bHideModalHeaderClose="hideModal('classroom-modal')">
                <template v-slot:CommonModalTitle>{{ $t('classroom') }}</template>
                <template v-slot:CommonModalContent>
                    <div>
                        <b-form-group :label="$t('classroom')">
                            <b-form-select
                                v-model="classroom_name"
                                value-field="name"
                                text-field="name"
                                :options="classroom_values"
                            ></b-form-select>
                        </b-form-group>
                        <b-button variant="primary" @click="downloadAsPdf" :label="$t('show')"/>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="exam-report-modal" :onHideOnlyX="true" @bHideModalHeaderClose="hideModal('exam-report-modal')">
                <template v-slot:CommonModalTitle>{{ $t('classroom') }}</template>
                <template v-slot:CommonModalContent>
                    <div>
                        <b-form-group :label="$t('classroom')">
                            <b-form-select
                                v-model="classroom_name"
                                value-field="name"
                                text-field="name"
                                :options="classroom_values"
                            ></b-form-select>
                        </b-form-group>
                        <b-button variant="primary" @click="downloadAsPdfExamReport" :label="$t('exam_report_all_section')"/>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="students-list-modal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="hideModal('students-list-modal')">
                <template v-slot:CommonModalTitle>{{ $t('students') }}</template>
                <template v-slot:CommonModalContent>
                    <div class="max">
                        <div class="spinner over" v-show="formLoading">
                            <div class="d-flex justify-content-center py-5">
                                <b-spinner label="Spinning" class="text-primary"></b-spinner>
                            </div>
                        </div>
                        <div v-show="!formLoading">
                            <b-row>
                                <b-col cols="12">
                                    <div v-if="students && students.length">
                                        <b-table bordered striped hover
                                                 :fields="studentsField"
                                                 :items="students"
                                                 caption-top
                                        >
                                            <template #cell(is_repeat)="row">
                                                {{row.item.is_repeat == true ? $t('repeat') : $t('first_time')}}
                                            </template>
                                        </b-table>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import ExamScheduleService from "@/services/ExamScheduleService";
import StudentService from "@/services/StudentService";
import StudentDocumentService from "@/services/StudentDocumentService"
import {EventBus} from "@/main";
import CommonModal from "@/components/elements/CommonModal.vue";

//other
import JsonExcel from "vue-json-excel";

export default {
    components: {
        CommonModal,
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,

        JsonExcel


    },
    metaInfo() {
        return {
            title: this.$t("exam_schedule1"),
        };
    },
    data() {
        return {
            examExcel:[],
            studentLists: {
                summary: [],
                students: [],
            },
            exam_id: null,
            student_content: null,
            classroom_id: null,
            classroom_name: null,
            classroom_values: [],
            isBackBtn: false,
            exam_schedule_columns: [
                {
                    label: "",
                    field: "buttons",
                    html: true,
                    sortable: false,
                    tdClass: "p-0 text-center w-40 align-middle",
                    buttons: [
                        {
                            text: this.toUpperCase("students"),
                            class: "ri-file-user-line",
                            permission: "studentdocument_examreport",
                            callback: ({id}) => {
                                this.downladStudents(id);
                            },
                        },
                        {
                            text: this.toUpperCase("exam_report"),
                            class: "ri-file-download-line",
                            permission: "studentdocument_examreport",
                            callback: (row) => {
                                this.showModal(row, 'classroom-modal');
                            },
                        },
                        {
                            text: this.toUpperCase("exam_report_all_section"),
                            class: "ri-file-download-line",
                            permission: "studentdocument_allsectionexamreport",
                            callback: (row) => {
                                this.showModal(row, 'exam-report-modal');
                            },
                        },
                        {
                            text: this.toUpperCase("exam_report_list"),
                            class: "ri-download-line",
                            permission: "studentdocument_examreportlist",
                            callback: ({id}) => {
                                this.downloadAsExamReportList(id)
                            },
                        },
                    ],
                },
                {
                    label: this.toUpperCase("date"),
                    field: "date",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("start_time"),
                    field: "start_time",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("end_time"),
                    field: "end_time",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("course_code"),
                    field: "course_code",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("course_name"),
                    field: this.getLocaleField("course_name"),
                    sortable: false,
                },
                {
                    label: this.toUpperCase("classrooms"),
                    field: "classrooms",
                    sortable: false,
                    formatFn: (value) => {
                        let data = [];
                        value.map((item) => {
                            data.push(item.name);
                        });
                        return data.join(", ");
                    },
                },
                {
                    label: this.toUpperCase("exam_type"),
                    field: this.getLocaleField('type'),
                    sortable: false,
                },
                {
                    label: this.toUpperCase("observers"),
                    field: "section",
                    sortable: false,
                    formatFn: (value, row) => {
                        let data = [];
                        row.classrooms.map((item) => {
                            item.observers.map((observer) => {
                                data.push(observer);
                            });
                        });
                        return data.join(", ");
                    },
                },
            ],
            student_columns: [
                {
                    label: this.toUpperCase("student_number"),
                    field: "student_number",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("name"),
                    field: "name",
                    sortable: false,
                    formatFn: (value, row) => {
                        return row.name + " " + row.surname;
                    },
                },
                {
                    label: this.toUpperCase("program_name"),
                    field: "program_name",
                    sortable: false,
                },
                {
                    label: "SECTION",
                    field: "section_number",
                    sortable: false,
                },
            ],
            datatable: {
                isLoading: false,
                columns: [],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                showPaginate: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: -1,
                },
            },
            selectedFunc: 0,
            formLoading: false,
            students: null,
            studentsField: [
                {
                    label: this.toUpperCase("student_number"),
                    key: "student_number",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("name"),
                    key: "name",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("surname"),
                    key: "surname",
                    sortable: false,
                },
                {
                    label: this.toUpperCase("faculty"),
                    key: this.getLocaleField("faculty_name"),
                    sortable: false,
                },
                {
                    label: this.toUpperCase("program"),
                    key: this.getLocaleField("program_name"),
                    sortable: false,
                },
                {
                    label: this.toUpperCase("section"),
                    key: 'section',
                    sortable: false,
                },
                {
                    label: this.toUpperCase("class"),
                    key: 'class',
                    sortable: false,
                },
                {
                    label: this.toUpperCase("status"),
                    key: 'is_repeat',
                    sortable: false,
                },
            ],
            examFieldsJson: {
                [this.$t('date')]: 'date',
                [this.$t('start_date')]: 'start_time',
                [this.$t('end_date')]: 'end_time',
                [this.$t('course_code')]: 'course_code',
                [this.$t('course_name')]: 'course_name',
                [this.$t('classrooms')]: 'classroom',
                [this.$t('section')]: 'section',
                [this.$t('exam_type')]: 'type',
                [this.$t('observers')]: 'observer',
            },
        };
    },
    created() {
        this.getInstructor();
    },
    methods: {
        backBtn() {
            this.getInstructor();
            this.isBackBtn = false;
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        getInstructor() {
            this.datatable.columns = this.exam_schedule_columns;
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                    sort: "name",
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return ExamScheduleService.getInstructor(config)
                .then((response) => {
                     response.data.data.forEach(item=>{
                         const classroomNames = item.classrooms.map(value => value.name);// Tüm sınıf isimlerini içeren bir dizi
                         const observerNames=item.classrooms.map(value => value.observers[0]);
                         item.classroom = classroomNames.join(', '); // Sınıf isimlerini virgülle birleştir
                         item.observer = observerNames.join(', ')
                         this.examExcel.push(item)
                     })
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.data.length;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        getStudents() {
            this.isBackBtn = true;
            this.datatable.columns = this.student_columns;
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            return StudentService.getInstructorStudents(
                ...this.student_content, this.classroom_id
            )
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.data.length;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                })
        },
        downloadAsExamReportList(exam_id) {
            StudentDocumentService.downloadExamListReport(exam_id)
                .then(response => {
                    let fileName = this.$t('exam_report_list') + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                })
                .catch(e => {
                    this.showErrors(e, null, true)
                })
        },
        showModal(data, modal) {
            this.exam_id = data.id;
            this.classroom_values = data.classrooms;
            this.defaultClassroom(data.classrooms);
            this.student_content = [
                data.semester_id,
                data.course_id,
                data.section]
            this.$refs[modal].$refs.commonModal.show()
        },
        hideModal(modal) {
            this.student_content = null
            this.$refs[modal].$refs.commonModal.hide()
        },
        downloadAsPdf() {
            if (this.classroom_name == null) {
                this.$toast.error(this.$t('classroom_not_selected'));
                return
            }
            StudentDocumentService.downloadExamReport(this.exam_id, this.classroom_name)
                .then(response => {
                    let fileName = this.$t('students') + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                })
                .catch(e => {
                    this.showErrors(e, null, true)
                })
            this.hideModal('classroom-modal');
        },
        defaultClassroom(classrooms) {
            if (classrooms.length > 0) return this.classroom_name = classrooms[0].name
        },
        downladStudents(exam_id) {
            this.formLoading = true;
            ExamScheduleService.showStudents(exam_id)
                .then(response => {
                    this.formLoading = false;
                    this.$refs['students-list-modal'].$refs.commonModal.show()
                    this.students = response.data.data;
                })
                .catch(e => {
                    this.showErrors(e, null, true)
                })
            this.hideModal('students-list-modal');
        },
        downloadAsPdfExamReport() {
            if (this.classroom_name == null) {
                this.$toast.error(this.$t('classroom_not_selected'))
                return
            }

            StudentDocumentService.downloadExamReportAllSection(this.exam_id, this.classroom_name)
                .then(response => {
                    let fileName = this.$t('students') + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                })
                .catch(e => {
                    this.showErrors(e, null, true)
                })
            this.hideModal('exam-report-modal');
        }
    },
};
</script>
